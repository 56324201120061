
<template>
    <div class="mobile-page-layout">
        <div class="mobile-page-header" style="border-bottom: 1px solid #e6e6e6;">
            <div class="mobile-page-header-row">
                <div class="mobile-page-header-right">
                    <router-link :to="{ name: 'HomeIntro'}" class="mobile-page-header-icon pointer back-icon">
                        <i class="header-uil-icon uil uil-angle-right-b"></i>
                    </router-link>
                    <!-- <div class="mobile-page-header-icon">
                        <svg class="header-svg-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                            <path class="fa-primary" d="M360 144h-208C138.8 144 128 154.8 128 168S138.8 192 152 192h208C373.3 192 384 181.3 384 168S373.3 144 360 144zM264 240h-112C138.8 240 128 250.8 128 264S138.8 288 152 288h112C277.3 288 288 277.3 288 264S277.3 240 264 240z" />
                            <path class="fa-secondary" d="M447.1 0h-384c-35.25 0-64 28.75-64 63.1v287.1c0 35.25 28.75 63.1 64 63.1h96v83.1c0 9.75 11.25 15.45 19.12 9.7l124.9-93.7h144c35.25 0 64-28.75 64-63.1V63.1C511.1 28.75 483.2 0 447.1 0zM264 288h-112C138.8 288 128 277.3 128 264S138.8 240 152 240h112C277.3 240 288 250.8 288 264S277.3 288 264 288zM360 192h-208C138.8 192 128 181.3 128 168S138.8 144 152 144h208C373.3 144 384 154.8 384 168S373.3 192 360 192z" />
                        </svg>
                    </div> -->
                    <div class="mobile-page-header-text">
                        صرافی‌های ارزی
                    </div>  
                </div>
                <div class="mobile-page-header-actions">  
                    <div class="header-actions-sw">
                        <div :class="'header-actions-sw-item ' + (type == 'sell' ? 'sw-item-active' : '')" @click="updateType('sell')">فروش</div>
                        <div :class="'header-actions-sw-item ' + (type == 'buy' ? 'sw-item-active' : '')" @click="updateType('buy')">خرید</div>
                    </div>
                    <!-- <div class="mobile-page-header-menu">
                        <template>
                            <div class="text-center">
                                <v-menu offset-y>
                                <template v-slot:activator="{ on, attrs }">
                                    <div class="mobile-page-header-icon pointerv margin-0" v-bind="attrs" v-on="on">
                                        <i class="header-material-icon mdi mdi-dots-vertical"></i>
                                    </div>
                                </template>
                                <v-list class="header-help-list">
                                    <v-list-item v-for="(item, index) in menus" :key="index"  @click.prevent="get_modal(item.modal)">
                                        <v-list-item-title>{{ item.title }}</v-list-item-title>
                                    </v-list-item>
                                </v-list>
                                </v-menu>
                            </div>
                        </template>
                    </div> -->
                </div>
            </div>
        </div>

        <slot v-if="loading">
            <div class="tgju-widget-content full-padding">
                <div class="text-center p-2">
                    <div class="widget-loading"></div>
                </div>
            </div>
        </slot>

        <div v-else class="market-main-items-data">
            <!-- <div class="market-main-item breakline-active">
                <div class="breakline-row"> طلا </div>
            </div> -->
            <div class="page-market-select-row" :key="reload">
                <h3>صرافی ها</h3>
                <div class="page-market-select-input">

                    <v-select :items="items" v-model="selectCoin" class="profile-mobile-view-select">
                        <template v-slot:selection="{ item }">
                            <img class="vselect-image" :src="'https://static.tgju.org/views/default/images/currency-logos/' + selectCoin.slug.replace('ex', '') + '.svg?1'"> <span class="vselect-span">{{ item.name }}</span>
                        </template>
                        <template v-slot:item="{ item }">
                            <img class="vselect-image" :src="'https://static.tgju.org/views/default/images/currency-logos/' + item.slug.replace('ex', '') + '.svg?1'"> <span class="vselect-span">{{ item.name }}</span>
                        </template>
                    </v-select>

                </div>
            </div>

            <div v-for="(item, index) in currencyExchange" :key="index" class="market-main-item ">
                <slot v-for="item_sell in items" >
                    <router-link class="market-main-item-row r-low " :to="{ name: 'CurrencyExchangeProfile', params: { id: item.id, slug : item.type },}"  :key="item_sell.slug" v-if="item_sell.slug == selectCoin.slug && item.items[item_sell.slug + '-' +slugType]">
                        <div class="market-main-item-col-logo">
                            <img v-if="item.supplier && item.supplier.image !== null" :src="'https://platform.tgju.org/files/images/'+ item.supplier.image" :alt="item.category_title">
                            <img v-else src="https://static.tgju.org/views/default/images/tgju-light-logo.svg">
                        </div>
                        <div class="market-main-item-col item-col-right">
                            <div class="market-main-item-title">
                                <h2>{{ item.category_title }}</h2>
                            </div>
                            <div class="market-main-item-time"><i class="uil uil-clock"></i><span>{{ item.items[item_sell.slug + '-' +slugType] ? to_yyyy_mm_dd(item.items[item_sell.slug + '-' + slugType].updated_at) : '0' }}</span></div>
                        </div>
                        <div class="market-main-item-col item-col-left">
                            <div class="market-main-item-price"><span>{{ item.items[item_sell.slug + '-' +slugType] ? formatPrice(item.items[item_sell.slug + '-' +slugType].p)  : '0' }}</span> <div class="item-price-badge" style="padding-left: 0;padding-right: 0px;">ریال</div></div>
                            <div :class="'market-main-item-change ' +  (item.items[item_sell.slug + '-' +slugType] ? (item.items[item_sell.slug + '-' +slugType] ? item.items[item_sell.slug + '-' +slugType].dt : '') : '')"> {{ item.items[item_sell.slug + '-' +slugType] ? formatPrice((item.items[item_sell.slug + '-' +slugType] ? item.items[item_sell.slug + '-' +slugType].d : '')) : '' }} ({{ item.items[item_sell.slug + '-' +slugType] ? (item.items[item_sell.slug + '-' +slugType] ? item.items[item_sell.slug + '-' +slugType].dp : '') : '' }} %) </div>
                        </div>
                    </router-link>
                </slot>

            </div>
        </div>

    </div>
</template>



<script>
import moment from "moment-jalaali";

export default {
    name: 'CurrencyExchangeView',
    component: {
    },
    data: function () {
        return {
            currencyExchange: {},
            reload : 1,
            slugType : this.$route.params.type ?? 'sell',
            selectCoin:  { slug: 'usd', name: 'دلار'},
            loading: false,
            type: this.$route.params.type ?? 'sell',
            items: [
                // { slug: 'exusd', name: 'دلار توافقی'},
                // { slug: 'exeur', name: 'یورو توافقی'},
                { slug: 'irec', name: 'سکه امامی'},
                { slug: 'irhc', name: 'نیم سکه'},
                { slug: 'irqc', name: 'ربع سکه'},
                { slug: 'usd', name: 'دلار'},
                { slug: 'aed', name: 'درهم امارات'},
                { slug: 'eur', name: 'یورو'},
                { slug: 'gbp', name: 'پوند انگلیس'},
                { slug: 'cad', name: 'دلار کانادا'},
                { slug: 'aud', name: 'دلار استرالیا'},
                { slug: 'try', name: 'لیر ترکیه'},
                { slug: 'chf', name: 'فرانک سوئیس'},
                { slug: 'sek', name: 'کرون سوئد'},
                { slug: 'nok', name: 'کرون نروژ'},
                { slug: 'dkk', name: 'کرون دانمارک'},
                { slug: 'cny', name: 'یوان چین'},
                { slug: 'jpy', name: 'ین ژاپن'},
                { slug: 'sar', name: 'ریال عربستان'},
                { slug: 'kwd', name: 'دینار کویت'},
                { slug: 'qar', name: 'ریال قطر'},
                { slug: 'myr', name: 'رینگیت مالزی'},
                { slug: 'omr', name: 'ریال عمان'},
                { slug: 'iqd', name: 'دینار عراق'},
                { slug: 'rub', name: 'روبل روسیه'},
                { slug: 'thb', name: 'بات تایلند'},
                { slug: 'inr', name: 'روپیه هند'},
                { slug: 'sgd', name: 'دلار سنگاپور'},
                { slug: 'azn', name: 'منات آذربایجان'},
                { slug: 'amd', name: 'درام ارمنستان'},
                { slug: 'afn', name: 'افغانی'},
            ],
        }
    },
    mounted() {
        // دریافت اطلاعات تحلیل های تایید شده ی عمومی
        this.loadData();
    },
    methods: {
        formatPrice(value) {
            if (value === null) {
                return '0';
            }

            value = parseFloat(value);
            return parseFloat(value.toFixed(2)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },
        updateType(type) {
            this.type = type;
            this.slugType = type;
            this.reload++;
        },
        // نمایش زمان بصورت 1401/03/12
        to_yyyy_mm_dd(date) {
            return moment(date).format("H:m:s - jYYYY/jMM/jDD");
        },
        loadData() {
            this.loading = true;
            this.$helpers.makeRequest('GET', '/exchanges/currency-exchange').then(api_response => {
                if ( api_response.status == 200 ) {
                    this.currencyExchange = api_response.data;
                    this.loading = false;
                }
            });
        }
    }
}
</script>
